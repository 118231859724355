import React from 'react';
import { useForm } from 'react-hook-form';
import styles from './styles.css';
import { useI18n } from '../../utils/i18n';
import SearchIconOutline from '../../public/icons/SVG/search-outline.svg';

export const TranslationsNeeded = 'components/SearchField/translations';

interface FormData {
  search: string;
}

const Component: React.FC = () => {
  const { translations } = useI18n(TranslationsNeeded);

  const {
    register, handleSubmit, setValue,
  } = useForm<FormData>();

  const onSubmit = handleSubmit(({ search }) => {
    window.alert(search);
  });

  const [searchValue, setSearchValue] = React.useState<string>('');
  return (
    <form
      className={styles.searchForm}
      onSubmit={onSubmit}
    >
      <input
        name="search"
        type="search"
        id="search"
        aria-label="Search through site content"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSearchValue(e.target.value);
        }}
        className={styles.searchInput}
        placeholder={translations.placeholder as string}
        value={searchValue}
        tabIndex={0}
        ref={register}
      />

      <button
        type="submit"
        className={styles.submitButton}
      >
        <SearchIconOutline />
      </button>
    </form>
  );
};

export const AllTranslationsNeeded: string[] = [
  TranslationsNeeded,
];

export default Component;
