import React from 'react';
import styles from './styles.css';
import { useI18n, Link, changeDocumentLanguage } from '../../utils/i18n';

import SearchField, { AllTranslationsNeeded as SearchFieldAllTranslationsNeeded } from '../SearchField';

import limitWidthStyles from '../Common/limit-width.css';

export const TranslationsNeeded = 'components/SearchSection/translations';

const Component: React.FC = () => {
  const { translations, language } = useI18n(TranslationsNeeded);
  const [buyOrRent, setBuyOrRent] = React.useState<'buy' | 'rent'>('buy');
  return (
    <section
      id="search"
      className={[styles.section, limitWidthStyles.limitWidth].join(' ')}
    >
      <div className={styles.sectionContent}>
        <h1 className={styles.title}>
          {translations.title}
        </h1>

        <button
          className={styles.buyOrRentDiv}
          onClick={() => {
            setBuyOrRent(buyOrRent === 'buy' ? 'rent' : 'buy');
          }}
          type="button"
        >
          <p className={styles.buyOrRentText}>
            {translations.buy}
          </p>

          <p className={styles.buyOrRentText}>
            {translations.rent}
          </p>

          <div
            style={{
              top: 0,
              bottom: 0,
            }}
            className={[
              styles.buyOrRentSlider,
              ((buyOrRent === 'rent' && language === 'en') || (buyOrRent === 'buy' && language === 'ar')) ? styles.buyOrRentSlider_right : styles.buyOrRentSlider_left,
            ].join(' ')}
          />
        </button>

        <div className={styles.searchFieldDiv}>
          <SearchField />
        </div>

        <p className={styles.availableIn}>
          {
            translations.availableIn
          }
          <Link language={language === 'en' ? 'ar' : 'en'}>
            <a
              className={styles.switchLanguageLink}
              onClick={() => {
                changeDocumentLanguage(language === 'en' ? 'ar' : 'en');
              }}
              onKeyDown={() => {
                changeDocumentLanguage(language === 'en' ? 'ar' : 'en');
              }}
              role="button"
              tabIndex={0}
            >
              {language === 'en' ? 'عربي' : 'English'}
            </a>
          </Link>
        </p>
      </div>
    </section>
  );
};

export const AllTranslationsNeeded: string[] = [
  TranslationsNeeded,
  ...SearchFieldAllTranslationsNeeded,
];

export default Component;
