import React from 'react';
import {
  NextPage,
  GetStaticPaths,
  GetStaticProps,
} from 'next';
import Head from 'next/head';
import {
  getI18nStaticPaths,
  withI18n,
  getI18nProps,
  GetI18nProps,
  GetI18nQuery,
  useI18n,
} from '../../utils/i18n';

import i18nConfig from '../../i18n.config';

import NavHeader, { AllTranslationsNeeded as NavHeaderTranslations } from '../../components/NavHeader';
import SearchSection, { AllTranslationsNeeded as SearchSectionTranslations } from '../../components/SearchSection';
import BottomNav, { AllTranslationsNeeded as BottomNavTranslations } from '../../components/BottomNav';

const { domains } = i18nConfig;
const TranslationsNeeded = 'pagesTranslations/[language]/index';

const Page: NextPage = () => {
  const { translations } = useI18n(TranslationsNeeded);
  return (
    <>
      <Head>
        <meta name="title" content={translations.title as string} key="title" />
        <title>{translations.title}</title>
        <link rel="alternate" href={process.env.NODE_ENV === 'production' ? domains.production : domains.development} hrefLang="x-default" />
      </Head>
      <NavHeader />
      <SearchSection />
      <BottomNav />
    </>
  );
};

export const getStaticPaths: GetStaticPaths = async () => ({
  paths: [
    ...getI18nStaticPaths(),
  ],
  fallback: false,
});

export const getStaticProps: GetStaticProps<GetI18nProps, GetI18nQuery> = async ({
  params,
}) => ({
  props: {
    ...await getI18nProps({
      language: params?.language as string,
      paths: [
        TranslationsNeeded,
        ...SearchSectionTranslations,
        ...NavHeaderTranslations,
        ...BottomNavTranslations,
      ],
    }),
  },
});

export default withI18n(Page, '');
