import React from 'react';
import { useRouter } from 'next/router';
import styles from './styles.css';
import { useI18n, Link } from '../../utils/i18n';
import anchorStyles from '../Common/anchor.css';

import ProfileIconOutline from '../../public/icons/SVG/person-outline.svg';
import ProfileIcon from '../../public/icons/SVG/person.svg';
import HeartIcon from '../../public/icons/SVG/heart.svg';
import HeartIconOutline from '../../public/icons/SVG/heart-outline.svg';
import SearchIconOutline from '../../public/icons/SVG/search-outline.svg';
import SearchIcon from '../../public/icons/SVG/search.svg';

export const TranslationsNeeded = 'components/BottomNav/translations';

const Component: React.FC = () => {
  const { translations } = useI18n(TranslationsNeeded);
  const router = useRouter();
  const pathname = router.pathname.substring(11); // removes "/[language]"
  return (
    <>
      <nav className={styles.bottomNav}>
        <Link
          href="/"
          passHref
        >
          <a
            className={[
              anchorStyles.anchor,
              styles.menuLink,
              pathname === '' || pathname.startsWith('/search') ? styles.menuLink_active : '',
            ].join(' ')}
          >
            {
              pathname === '' || pathname.startsWith('/search') ? (
                <SearchIcon />
              ) : (
                <SearchIconOutline />
              )
            }
          </a>
        </Link>

        <Link
          href="/likes"
          passHref
        >
          <a
            className={[
              anchorStyles.anchor,
              styles.menuLink,
              pathname.startsWith('/likes') ? styles.menuLink_active : '',
            ].join(' ')}
          >
            {
              pathname.startsWith('/likes') ? (
                <HeartIcon />
              ) : (
                <HeartIconOutline />
              )
            }
          </a>
        </Link>

        <Link
          href="/profile"
          passHref
        >
          <a
            className={[
              anchorStyles.anchor,
              styles.menuLink,
              pathname.startsWith('/profile') ? styles.menuLink_active : '',
            ].join(' ')}
          >
            {
              pathname.startsWith('/profile') ? (
                <ProfileIcon />
              ) : (
                <ProfileIconOutline />
              )
            }
          </a>
        </Link>
      </nav>
      <div className={styles.pushBody} />
    </>
  );
};

export const AllTranslationsNeeded: string[] = [
  TranslationsNeeded,
];

export default Component;
