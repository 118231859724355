import React from 'react';
import { useRouter } from 'next/router';
import styles from './styles.css';
import anchorStyles from '../Common/anchor.css';
import responsiveStyles from '../Common/responsive.css';
import LogoEnglish from '../../public/logo/SVG/logo_text_en.svg';
import LogoArabic from '../../public/logo/SVG/logo_text_ar.svg';

import ProfileIconOutline from '../../public/icons/SVG/person-outline.svg';
import ProfileIcon from '../../public/icons/SVG/person.svg';
import HeartIcon from '../../public/icons/SVG/heart.svg';
import HeartIconOutline from '../../public/icons/SVG/heart-outline.svg';
import SearchIconOutline from '../../public/icons/SVG/search-outline.svg';
import SearchIcon from '../../public/icons/SVG/search.svg';

import {
  useI18n,
  Link,
} from '../../utils/i18n';

export const TranslationsNeeded = 'components/NavHeader/translations';

const Component: React.FC = () => {
  const { translations, language } = useI18n(TranslationsNeeded);
  const router = useRouter();
  const pathname = router.pathname.substring(11); // removes "/[language]"
  return (
    <>
      <header className={styles.header}>
        <nav
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'space-between',
          }}
        >

          <Link href="/">
            <a className={styles.logoWrapper}>
              {
                language === 'ar' ? <LogoArabic /> : <LogoEnglish />
              }
            </a>
          </Link>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
              className={[
                responsiveStyles.hideOnMobile,
              ].join(' ')}
            >
              <Link
                href="/"
                passHref
              >
                <a
                  className={[
                    anchorStyles.anchor,
                    styles.menuLink,
                    pathname === '' || pathname.startsWith('/search') ? styles.menuLink_active : '',
                  ].join(' ')}
                >
                  {
                    pathname === '' || pathname.startsWith('/search') ? (
                      <SearchIcon />
                    ) : (
                      <SearchIconOutline />
                    )
                  }
                  <p>
                    {translations.search}
                  </p>
                </a>
              </Link>

              <Link
                href="/likes"
                passHref
              >
                <a
                  className={[
                    anchorStyles.anchor,
                    styles.menuLink,
                    pathname.startsWith('/likes') ? styles.menuLink_active : '',
                  ].join(' ')}
                >
                  {
                    pathname.startsWith('/likes') ? (
                      <HeartIcon />
                    ) : (
                      <HeartIconOutline />
                    )
                  }
                  <p>
                    {translations.likes}
                  </p>
                </a>
              </Link>

              <Link
                href="/profile"
                passHref
              >
                <a
                  className={[
                    anchorStyles.anchor,
                    styles.menuLink,
                    pathname.startsWith('/profile') ? styles.menuLink_active : '',
                  ].join(' ')}
                >
                  {
                    pathname.startsWith('/profile') ? (
                      <ProfileIcon />
                    ) : (
                      <ProfileIconOutline />
                    )
                  }
                  <p>
                    {translations.myProfile}
                  </p>
                </a>
              </Link>
            </div>

            <Link href="/sell" passHref>
              <a className={[
                anchorStyles.anchor,
                anchorStyles.button,
                anchorStyles.secondaryButton,
                anchorStyles.smallButton,
              ].join(' ')}
              >
                {translations.listYourPropertyButton}
              </a>
            </Link>
          </div>
        </nav>
      </header>
      <div className={styles.pushBody} />
    </>
  );
};

export const AllTranslationsNeeded: string[] = [
  TranslationsNeeded,
];

export default Component;
